<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="2"
      />
      <b-col
        cols="12"
        md="8"
      >
        <b-card>
          <validation-observer
            ref="infoRules"
            tag="form"
          >
            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="validationContext"
                  name="Phone Number"
                  rules="required"
                >
                  <b-form-group
                    label="Phone Number"
                    label-for="phone"
                  >
                    <b-form-input
                      id="phone"
                      v-model="userForm.phone_number"
                      autofocus
                      :state="getValidationState(validationContext)"
                      type="number"
                      placeholder="Phone Number"
                      autocomplete="off"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    <small
                      v-if="validationErrors.phone_number"
                      class="text-danger"
                    >{{ validationErrors.phone_number.toString() }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="validationContext"
                  name="Amount"
                  rules="required"
                >
                  <b-form-group
                    label="Amount"
                    label-for="amount"
                  >
                    <b-form-input
                      id="amount"
                      v-model.number="userForm.amount"
                      :state="getValidationState(validationContext)"
                      type="number"
                      placeholder="Amount"
                      autocomplete="off"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    <small
                      v-if="validationErrors.amount"
                      class="text-danger"
                    >{{ validationErrors.amount.toString() }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Pin"
                  rules="required|pincode"
                >
                  <b-form-group
                    label-for="account-new-pin"
                    label="Pin"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="account-new-password"
                        v-model="userForm.pin"
                        :type="passwordFieldTypeNew"
                        :state="errors.length > 0 ? false:null"
                        name="new-password"
                        placeholder="Pin"
                        autocomplete=""
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIconNew"
                          class="cursor-pointer"
                          @click="togglePasswordNew"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="validationErrors.pin"
                      class="text-danger"
                    >{{ validationErrors.pin.toString() }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="12">
                <validation-provider
                  #default="validationContext"
                  name="Reason"
                  rules="required"
                >
                  <b-form-group
                    label="Reason"
                    label-for="phone"
                  >
                    <b-form-textarea
                      id="phone"
                      v-model="userForm.description"
                      autofocus
                      :state="getValidationState(validationContext)"
                      type="text"
                      placeholder="Reason"
                      autocomplete="off"
                      rows="4"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    <small
                      v-if="validationErrors.phone_number"
                      class="text-danger"
                    >{{ validationErrors.phone_number.toString() }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                class="mt-50"
              >
                <b-button
                  v-if="!loader"
                  variant="primary"
                  class="mr-1"
                  @click="sendMoney"
                >
                  Send
                </b-button>
                <b-button
                  v-if="loader"
                  variant="primary"
                  disabled
                  class="mr-1"
                >
                  <b-spinner
                    small
                    type="grow"
                  />
                  Loading...
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="2"
      />
    </b-row>
  </div>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api'
import { required } from '@validations'

export default {
  setup() {
    const userForm = ref({
      amount: '',
      phone_number: '',
      description: '',
      pin: '',
    })

    const passwordFieldTypeNew = ref('password')

    const loader = ref(false)

    const { getValidationState } = formValidation()

    const validationErrors = ref({
      pin: '',
      phone_number: '',
      description: '',
      amount: '',
    })

    return {
      userForm,
      getValidationState,
      required,
      passwordFieldTypeNew,
      loader,
      validationErrors,
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    sendMoney() {
      this.$refs.infoRules.validate().then(async success => {
        if (success) {
          this.loader = true
          try {
            const response = await axios.post('transactions/send-money', this.userForm)
            if (response.status === 200) {
              this.userForm = {}
              this.validationErrors = {
                amount: '',
                phone_number: '',
                description: '',
                pin: '',
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Sent Succesfully',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
            }
          } catch (error) {
            Object.assign(this.validationErrors, error.response?.data?.errors)
          } finally {
            this.loader = false
          }
        }
      })
    },
  },

}
</script>

<style>

</style>
